<template>
  <!-- begin::FormFields -->
  <div class="col-12 col-sm mr-2">
    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase">{{ $gettext(selectVal[fieldKey].name) }}</label>

    <!-- Render Inputs -->
    <b-form-group
      v-if="selectVal[fieldKey].type === 'input'"
      class="form-group-lg pt-3"
      label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
      :label-for="`input-${fieldKey}`"
      :description="$gettext(selectVal[fieldKey].description)"
    >
      <b-form-input
        :key="fieldKey"
        :id="`input-${fieldKey}`"
        class="mr-2 col"
        v-model="selectVal[fieldKey].value"
        size="lg"
        :description="$gettext(selectVal[fieldKey].description)"
      ></b-form-input>
    </b-form-group>

    <!-- Render Select -->
    <b-form-group
      v-if="selectVal[fieldKey].type === 'select'"
      class="form-group-lg pt-3"
      label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
      :label-for="`select-${fieldKey}`"
      :description="$gettext(selectVal[fieldKey].description)"
    >
      <select :key="fieldKey" :id="`select-${fieldKey}`" class="form-control form-control-lg" @click.stop.prevent v-model="selectVal[fieldKey].value">
        <option :value="undefined"><translate>Select...</translate></option>
        <option :value="option" v-for="(option, index) in options" :key="index">
          {{ $gettext(getOptionNamebyKeys(fieldKey, option, selectVal[fieldKey].label !== undefined ? selectVal[fieldKey].label : "Name")) }}
        </option>
      </select>
    </b-form-group>

    <!-- Render Boolean -->
    <b-form-group
      v-if="selectVal[fieldKey].type === 'boolean'"
      class="form-group-lg pt-3"
      label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
      :label-for="`select-${fieldKey}`"
      :description="$gettext(selectVal[fieldKey].description)"
    >
      <select :key="fieldKey" :id="`select-${fieldKey}`" class="form-control form-control-lg" @click.stop.prevent v-model="selectVal[fieldKey].value">
        <option :value="undefined"><translate>Select...</translate></option>
        <option :value="true"><translate>Yes</translate></option>
        <option :value="false"><translate>No</translate></option>
      </select>
    </b-form-group>

    <!-- Render Checkboxes -->
    <b-form-group
      v-if="selectVal[fieldKey].type === 'checkbox'"
      class="form-group-lg pt-3 mt-2 row d-flex"
      buttons
      label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
      :label-for="`checkbox-${fieldKey}`"
      :description="$gettext(selectVal[fieldKey].description)"
    >
      <b-form-checkbox :key="fieldKey" :id="`checkbox-${fieldKey}`" class="ml-3" @click.stop.prevent v-model="selectVal[fieldKey].value" size="lg">
        {{ $gettext(selectVal[fieldKey].name) }}
      </b-form-checkbox>
    </b-form-group>
  </div>
  <!-- end::FormFields -->
</template>

<script>
//import Swal from "sweetalert2";

export default {
  name: "FormFields",
  props: {
    selectVal: {
      type: Object,
      default: null,
    },
    fieldKey: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  methods: {
    getOptionNamebyKeys(key, option, label) {
      let parent = this.$parent;
      let hasParent = true;

      console.log("getOptionNamebyKeys::");
      console.log("getOptionNamebyKeys::", "key", key, "option", option, "label", label);

      // console.log("Switch parent > ", key);
      // console.log("Parent ? ", parent);
      // console.log("Parent.hasFormFields ? ", parent.hasFormFields);

      // Find keys objects in parent
      //while (parent && parent[key] === undefined ) {
      while (parent && parent.hasFormFields !== true) {
        parent = parent.$parent;

        // console.log("Switch parent > ", key);
        // console.log("Parent ? ", parent);
        // console.log("Parent.hasFormFields ? ", parent.hasFormFields);
      }

      try {
        parent[key] !== undefined;
      } catch (e) {
        hasParent = false;
        console.log("We don't find a dictionnary so abord", e);

        // Then, message
        window.swalAlert.fire({
          title: this.$gettext("Filters error"),
          html: this.$gettext("Please contact our support."),
          // timer: 1200,
          // timerProgressBar: true,
          confirmButtonClass: "btn btn-outline-secondary",
          // heightAuto: false,
          icon: "error",
          footer: `<code>We don't find a dictionnary so abord</code>`,
        });
      }

      // Find correct options names
      if (
        hasParent == true &&
        parent[key] != undefined &&
        parent[key][this.options.indexOf(option)] != undefined &&
        parent[key][this.options.indexOf(option)][label] != ""
      ) {
        // For example : Special case for CountryISO ISO2:Name
        let needle = "";
        if (key == "CountryISO") needle = "ISO2";
        if (key == "MainRole") needle = "id";

        // Start looking
        if (needle != "") {
          console.log("getOptionNamebyKeys::", key, needle, typeof option, parent[key]);
          if (typeof option !== "undefined" && option !== "") {
            try {
              return parent[key][
                parent[key].findIndex((c) => {
                  return c[needle] == option;
                })
              ][label];
            } catch (e) {
              // Log
              console.log("getOptionNamebyKeys::", key, "We don't find a [NEEDLE] specific so returning '—'", e);

              // Then, message
              window.swalAlert.fire({
                title: this.$gettext("Filters error"),
                html: this.$gettext("Please contact our support."),
                // timer: 1200,
                // timerProgressBar: true,
                confirmButtonClass: "btn btn-outline-secondary",
                // heightAuto: false,
                icon: "error",
                footer: `<code>GetOptionNamebyKeys | ${key} | ${option}</code>`,
              });

              // Then, unknown option name
              return "-";
            }
          } else return "—"; // Return unknown option name
        }
        // Standard case
        else return parent[key][this.options.indexOf(option)][label];
      } else {
        // We don't find a dictionnary, so abord
        return option; // Return known option name
      }
    },
  },
};
</script>
